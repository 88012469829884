import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: any
  languageCode: string
  name: string
  url: string
}

export const Offer = memo(function Offer({
  description,
  image,
  languageCode,
  name,
  url,
}: Props) {
  return (
    <Container href={url} rel="noopener" target="_blank">
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Thumb
              className="thumb"
              alt={name}
              src={image.thumb}
              width="560"
              height="360"
            />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Wrapper className="wrapper">
        <Title>
          <Inner>{name}</Inner>
        </Title>

        {description ? (
          <Description
            dangerouslySetInnerHTML={{
              __html: description
                .replace(/(<([^>]+)>)/gi, '')
                .replace(/(\r\n|\n|\r)/gm, ''),
            }}
          />
        ) : null}

        <CTA
          label={useVocabularyData('find-out-more', languageCode)}
          className="cta"
          noActions
          variant="simple"
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled.a`
  width: calc(50% - 3.75rem);
  margin: 6.25rem 3.75rem 0 0;
  &:hover {
    .thumb {
      transform: scale(1.05);
    }
    .wrapper {
      transform: translateY(-1.25rem);
    }
    .cta {
      &:after {
        right: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    margin: 5rem 0 0;
    &:hover {
      .thumb {
        transform: none;
      }
      .wrapper {
        transform: none;
      }
    }
  }
`

const Background = styled.div`
  height: 40vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;

  @media (max-width: 1199px) {
    height: 20rem;
  }
`

const Thumb = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
`

const Wrapper = styled.div`
  width: calc(100% - 3.75rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin: -6.25rem 0 0 1.875rem;
  padding: 2.625rem 3.125rem;
  position: relative;
  text-align: left;
  transition: 0.3s ease-in-out;
  z-index: 2;

  @media (max-width: 1199px) {
    width: calc(100% - 3rem);
    margin-left: 1.5rem;
    padding: 2.5rem 1.5rem;
  }
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  &:after {
    content: '';
    width: calc(45% - 2.125rem);
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-left: 2.125rem;
  }

  @media (max-width: 1199px) {
    display: block;
    &:after {
      display: none;
    }
  }
`

const Inner = styled.span`
  width: 55%;

  @media (max-width: 1199px) {
    width: auto;
  }
`

const Description = styled.div`
  display: -webkit-box;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  margin-top: 1.25rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
`

const CTA = styled(Button)`
  margin-top: 1.875rem;
`
